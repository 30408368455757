import React from "react";
export const MillenniumSpinIn = (
  <div className="about-body">
    <h1 className="about-header">Fund Announcement</h1>
    <p>
      In March 2024, Crescent Asset Management was acquired by{" "}
      <a href="https://www.mlp.com" target="_blank" rel="noopener noreferrer">
        Millennium Capital Management
      </a>
      . Millennium is a global investment management with a multi-strategy fund
      offering. It is one of the world's largest asset management firms with
      over $61.1 billion under management as of January 2024. The firm operates
      in America, Europe and Asia.
    </p>
  </div>
);
