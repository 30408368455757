import React from "react";
import { MillenniumSpinIn } from "../data/NewDisclosure";
import "./About.css";

function AboutPage() {
  return (
    <div className="about-page">
      <div className="about-container">{MillenniumSpinIn}</div>
    </div>
  );
}

export default AboutPage;
