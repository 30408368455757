import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import useAuthContext from "./hooks/useAuthContext";
import Spinner from "./components/Spinner";
import Navbar from "./components/Navbar";
import Page from "./pages/Page";
import About from "./pages/About";
import TeamPage from "./pages/TeamPage";
import ContactPage from "./pages/ContactPage";
import DisclaimerPage from "./pages/DisclaimerPage";
import LoginPage from "./pages/LoginPage";
import ChangePassword from "./pages/ChangePassword";
import InvestorPage from "./pages/InvestorPage";
import PasswordReset from "./pages/PasswordReset";
import RequestLogin from "./pages/RequestLogin";
import NotFound404 from "./pages/NotFound404";
import "./App.css";

function App() {
  // context
  // -------
  const { isAcceptCookiePolicy, user, isLoading } = useAuthContext();

  // Spinner if Context not ready
  // ----------------------------
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="App" id="app">
      <Navbar />
      <Routes>
        <Route path="/" element={<Page />} />
        <Route path="/about" element={<About />} />
        {/*<Route path="/team" element={<TeamPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/legal" element={<DisclaimerPage />} />
        <Route
          path="/login"
          element={isAcceptCookiePolicy ? <LoginPage /> : <Navigate to="/" />}
        />
        <Route path="/requestlogin" element={<RequestLogin />} />
        <Route path="/passwdreset" element={<PasswordReset />} />
        <Route
          path="/changepasswd"
          element={user ? <ChangePassword /> : <Navigate to="/" />}
        />
        <Route
          path="/investor"
          element={
            user && isAcceptCookiePolicy ? (
              <InvestorPage />
            ) : (
              <Navigate to="/" />
            )
          }
        />*/}
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </div>
  );
}

export default App;
