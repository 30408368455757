const NavbarData = [
  {
    title: "Home",
    path: "/",
    element: "<Page />",
  },
  {
    title: "Team",
    path: "/team",
  },
  {
    title: "Contact",
    path: "/contact",
  },
  {
    title: "Legal",
    path: "/legal",
  },
];

const InvestorNavData = [
  ...NavbarData,
  {
    title: "Investor",
    path: "/investor",
  },
  {
    title: "Logout",
    path: "/",
    element: "<Page />",
  },
];

const NonInvestorNavData = [
  ...NavbarData,
  {
    title: "Login",
    path: "/login",
  },
];

const NewNavbarData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About",
    path: "/about",
  },
];

export { NavbarData, InvestorNavData, NonInvestorNavData, NewNavbarData };
