import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLogout from "../hooks/useLogout";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  NavbarData,
  InvestorNavData,
  NonInvestorNavData,
  NewNavbarData,
} from "../data/NavbarData";
import "./Navbar.css";

function Navbar() {
  // Context
  // -------
  const { user, isAcceptCookiePolicy } = useAuthContext();

  // Hooks
  // -----
  const location = useLocation();
  const { logout } = useLogout();
  const navigate = useNavigate();

  // Variables - Navbar Items dependent on whether a user 1) Accepts CookiePolicy and 2)Is logged in.
  // ---------

  const isContactPage = location.pathname === "/contact"; // boolean determines if on contact page

  const NavbarItems = NewNavbarData;
  // --------------------------
  // ** SCRUB THE ABOVE LINE AND UNCOMMENT THE BELOW LINES TO USE THE ORIGINAL NAVBAR ITEMS **
  // const NavbarItems = !isAcceptCookiePolicy
  //   ? NavbarData
  //   : user
  //   ? InvestorNavData
  //   : NonInvestorNavData;
  // --------------------------

  // State for whether SideBar is Open
  //----------------------------------
  const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  // State and UseEffect to determine width of view window
  // -----------------------------------------------------
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    isNarrowWidth();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // State to Determine if in a small window and html needs to be adjusted
  // ----------------------------------------------------------------------
  const [narrowWidth, setNarrowWidth] = useState(true);

  const isNarrowWidth = () => {
    if (width < 1000) {
      setNarrowWidth(true);
    } else {
      setNarrowWidth(false);
      setSidebar(false);
    }
  };

  // investorLogout() - Handle investor Logout
  // ----------------
  const investorLogout = () => {
    console.log("investor logging out");
    logout();
    navigate("/");
  };

  return (
    <>
      <div
        className={`navbar-container ${
          isContactPage ? "contact-page-color" : ""
        }`}
      >
        <div className="hamburger-icon">
          <FontAwesomeIcon icon={solid("bars")} onClick={toggleSidebar} />
        </div>
        {sidebar && narrowWidth && (
          <div className="nav-sidebar-container">
            <nav
              className={`nav-sidebar ${
                isContactPage ? "contact-page-color" : ""
              }`}
            >
              <ul>
                {NavbarItems.map((item, index) => (
                  <li key={index} onClick={toggleSidebar}>
                    <Link
                      to={item.path}
                      onClick={item.title === "Logout" ? investorLogout : ""}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        )}
        <div className="logo">
          <Link to={NavbarItems[0].path}>
            {!narrowWidth ? (
              <h2>
                Crescent <span>|</span>
                <span>Asset Management Asia</span>
              </h2>
            ) : (
              <h3>Crescent </h3>
            )}
          </Link>
        </div>
        {!sidebar && !narrowWidth && (
          <nav className="nav-horizontal-items">
            <ul>
              {NavbarItems.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.path}
                    onClick={item.title === "Logout" ? investorLogout : ""}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </>
  );
}

export default Navbar;
